import React from "react"
import ResponsiveLayout from "../components/ResponsiveLayout"
import PageTitle from "../components/ResponsiveLayout/pageTitle"
import medialinqButton from "../Images/medialinq-button-2.png"

const Donator = (props) => {
  let menuPages = [
          { title: "Sidor",
            data:
            [
              { title: "Startsida", data: {href: "/index.html"} },
              { title: "Donator", data: {href: "/donator/index.html"} },
              { title: "Nudgewall", data: {href: "/nudgewall/index.html"} },
              { title: "Nätskribent", data: {href: "/mottagare/index.html"} },
              { title: "Vanliga frågor", data: {href: "/faqs/index.html"} }
            ]
          }
      ]

  return (
      <ResponsiveLayout {...props} pages={menuPages}>
        <PageTitle {...props} pagename={{ short: "Donator", long: "Bli donator med MediaLinq"
        }}  />
        <div className="container">
          <div className="section-header">
            <h2>Bli donerande medlem</h2>
            <p>Med den gröna donationsknappen (se nedan) kan du med bara två
              klick belöna skribenter, bloggare och producenter av poddar och
              video, som du gillar. Gilla på riktigt!
              </p>
            <p style={{marginTop: "1em"}}>
              Allt du behöver göra är att först registrera dig som medlem i
              MediaLinq, ladda ditt konto med lite pengar och logga in din
              webbläsare (browser), så kan du sedan donera med bara två klick
              varje gång du ser den gröna knappen. Du kan donera så lite som en
              krona, men också större belopp.
              </p>
            <p style={{marginTop: "1em"}}>
              Du registrerar ditt medlemskap genom att gå
              till <a href={"https://www.medialinq.se/app/register/index.html"}>www.medialinq.se</a> eller
              genom att klicka på den första gröna MediaLinq-knapp du ser
              på någon webbsida (till exempel här nedan). Det kan också vara en
              länk till MediaLinq som
              denna: <a href={"https://www.medialinq.se/donera/index.html?r=c128b8a5-4616-4dd4-8897-b19dbde3fc11&s=referrer&a=1"}>
              https://www.medialinq.se/donera/index.html?r=c128b8a5-4616-4dd4-8897-b19...
              </a> eller en länkad text
              som: <a href={"https://www.medialinq.se/donera/index.html?r=c128b8a5-4616-4dd4-8897-b19dbde3fc11&s=referrer&a=1"}>
              "Gillade du? Donera 1 kr eler mer med MediaLinq".
            </a>
            </p>

            <h4>Så här gör du för att få ett eget skyddat donationskonto och ett medlemskap i MediaLinq</h4>

            <p>
              (En instruktionsvideo
              finns <a href={"https://fairsharemediaworld.se/medialike-registrering1"}>här</a> och en detaljerad
              beskrivning med skärmbilder finns <a href={"https://netmediasystems.se/att-bli-medlem"}>här</a>.)
            </p>

                <p style={{marginTop: "0.7em"}}>
                  1. Klicka på den första gröna MediaLinq-knapp du ser eller gå
                  till <a href={"https://www.medialinq.se"}>www.medialinq.se</a>.
                </p>
                <p style={{marginTop: "0.7em"}}>
                  2. Klicka sedan på Registrera och skriv in ditt riktiga namn eller ditt alias. Ett lösenord behöver
                  också anges. Din e-post behövs för att kunna kontakta dig om det blir problem med ditt konto eller
                  om MediaLinq behöver ändra avtalet eller om du glömt ditt lösenord. MediaLinq har ingen reklam och
                  vi säljer inte din e-postadress. När du registrerar dig godkänner du
                  våra <a href={"https://netmediasystems.se/medialinq-villkor-och-policy"}>villkor och policy</a>.
                </p>
                <p style={{marginTop: "0.7em"}}>
                  3. Omedelbart efter registreringen får du ett mejl från MediaLinq, där du inom en timme ska klicka på en
                  länk för att vi ska få din e-postadress bekräftad.
                </p>
                <p style={{marginTop: "0.7em"}}>
                  4. Registrering behöver du bara göra en gång om du inte själv avslutar ditt medlemskap.
                </p>
                <p style={{marginTop: "0.7em"}}>
                  5. Du loggar därefter in med ditt användarnamn och lösenord och kommer till ditt
                  konto. <span style={{fontWeight: "bold"}}>Logga aldrig
                    ut igen om du äger din dator, platta eller mobil. Det är inloggningen som
                  identifierar dig</span> så att du kan donera med bara två klick och inte behöva använda BankID vid varje donation.
                </p>
                <p style={{marginTop: "0.7em"}}>
                  6. Du gör sedan din första inbetalning till ditt konto hos MediaLinq på förslagsvis 200 kr men minst
                  100 kr. Använder du Swish så bekräftas beloppet direkt och du kan därefter donera sekundsnabbt tills
                  det är dags att fylla på ditt konto. Betalar du via bankgiro kan du donera ”på kredit” tills beloppet
                  är registrerat hos vår bank och hos MediaLinq. Då först meddelas mottagaren att en donation har genomförts.
                </p>
                <p style={{marginTop: "0.7em"}}>
                  7. Som registrerad och inloggad MediaLinq-medlem kan du donera belopp från 1 kr och uppåt med endast
                  två klick (dvs sekundsnabbt efter första inbetalningen). Mottagaren ser endast ditt namn/alias
                  och <span style={{fontWeight: "bold"}}>aldrig</span> din e-post.
                </p>

              <h4>En inloggning per dator du använder</h4>

            <p>
              Har du fler datorer, plattor eller mobiler som du använder vid läsning av artiklar med den speciella gröna
              donationsknappen behöver du även registrera dig <span style={{fontWeight: "bold"}}>första gången </span> för
              varje enhet du använder. Du använder då
              samma inloggningsdata (Användarnamn och lösenord) som du registrerat. Den webbläsare du använder blir då
              identifierad så att du kan göra donationer med endast två klick.
              </p>
            <p style={{marginTop: "1em"}}>
              Om datorn du använder är en jobbdator eller en publik dator på ett bibliotek eller
              liknande, <span style={{fontWeight: "bold"}}>måste</span> du logga ut när du lämnar datorn, Annars kan
              nästa användare använda ditt MediaLinq-konto för donationer. MediaLinq använder inte cookies (kakor) för
              att identifiera donatorn, så det räcker inte med att ta bort cookies!
            </p>

            <p style={{marginTop: "1em"}}>
            <a href="https://www.medialinq.se/donera/index.html?r=c128b8a5-4616-4dd4-8897-b19dbde3fc11&amp;s=referrer&amp;a=1">
              <img src={medialinqButton} className="img-fluid" alt="Img" style={{maxWidth: "360px"}}/>
            </a>
            </p>

            <div style={{marginTop: "2em"}} className="donera-container-medium donera-bordered">
              <iframe title="Donera"
                      className="donera_button"
                      data-header="Donera 1 kr eller mer"
                      data-extra="Välj belopp"
                      data-recipient="f26faaa8-9208-11ea-bb37-0242ac130002"
                      data-amounts="1,5,10"
                      scrolling="no"></iframe>
            </div>
          </div>
        </div>
      </ResponsiveLayout>
  )
}

export default Donator
